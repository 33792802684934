import React from 'react'

import Layout from '../components/layout'

import HeaderPage from '../components/HeaderPage'

const NotFoundPage = () => (
  <Layout>
    <div className="content">
      <div className="inner">
        <HeaderPage />
        <h3>Page Not Found</h3>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
